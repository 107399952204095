import { getCalendarApi } from 'services/apis/admin/calendar';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getCalendar = createAsyncThunk('calendar/GET', async (params, { rejectWithValue }) => {
  try {
    const response = await getCalendarApi(params);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.data);
  }
});

const initialState = {
  listCalendar: [],
  loading: false,
  error: {},
};

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,

  extraReducers: {
    [getCalendar.pending]: (state) => {
      state.loading = true;
    },
    [getCalendar.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getCalendar.fulfilled]: (state, action) => {
      state.listCalendar = action.payload.data;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: calendarReducer } = calendarSlice;
export default calendarReducer;
