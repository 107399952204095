import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBG2o_QfhRYOC4Qi8pGVMLgT4DwXmiH4Ts',
  authDomain: 'dev-fmstyle-e-learning.firebaseapp.com',
  projectId: 'dev-fmstyle-e-learning',
  storageBucket: 'dev-fmstyle-e-learning.appspot.com',
  messagingSenderId: '476358243581',
  appId: '1:476358243581:web:3e8279d4e80ddf3dcd3a94',
  measurementId: 'G-JCP96JYH9K',
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const getTokenFirebase = async () => {
  let currentToken = '';
  try {
    currentToken = await getToken(messaging, {
      vapidKey:
        'BOX57JAks--2fa1pV0DXJUuiMNjj_CvuCWZtgks_WexQ3nIe9PSKiWrZC5zFVozbpDG03-2b7mbQzd1SUDO58B8',
    });
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error);
  }
  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
