import request from 'services/request';

export const getListLessonApi = async (params) =>
  request({
    url: '/admin/lessons/list-when-create-course',
    method: 'GET',
    params,
  });

export const getListTutorApi = async (params) =>
  request({
    url: '/admin/lessons/list-tutor',
    method: 'GET',
    params,
  });

export const getListTeacherApi = async (params) =>
  request({
    url: '/admin/lessons/list-teacher',
    method: 'GET',
    params,
  });

export const getDetailsLessonApi = async (id) =>
  request({
    url: `/admin/lessons/detail/${id}`,
    method: 'GET',
  });

export const createLessonApi = async (data) =>
  request({
    url: '/admin/lessons/create',
    method: 'POST',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const updateLessonApi = async (id, data) =>
  request({
    url: `/admin/lessons/update/${id}`,
    method: 'PUT',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const deleteLessonApi = async (id) =>
  request({
    url: `/admin/lessons/delete/${id}`,
    method: 'DELETE',
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });
