import { getListLevelApi } from 'services/apis/admin/level';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getListLevel = createAsyncThunk(
  'level/GET_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListLevelApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  listLevel: [],
  paginate: {},
  loading: false,
  error: {},
};

const levelSlice = createSlice({
  name: 'level',
  initialState,

  extraReducers: {
    [getListLevel.pending]: (state) => {
      state.loading = true;
    },
    [getListLevel.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListLevel.fulfilled]: (state, action) => {
      state.listLevel = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: levelReducer } = levelSlice;
export default levelReducer;
