import {
  getListExamApi,
  getDetailsExamApi,
  getListCreatorApi,
  getListSkillsApi,
} from 'services/apis/admin/exam';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getListExam = createAsyncThunk('exam/GET_LIST', async (data, { rejectWithValue }) => {
  try {
    const response = await getListExamApi(data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.data);
  }
});

export const getDetailsExam = createAsyncThunk(
  'exam/GET_DETAILS',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getDetailsExamApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListCreator = createAsyncThunk(
  'exam/GET_LIST_CREATOR',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListCreatorApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListSkills = createAsyncThunk(
  'exam/GET_LIST_SKILLS',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListSkillsApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  listExam: [],
  details: {},
  paginate: {},
  listCreator: [],
  listSkills: [],
  loading: false,
  error: {},
};

const examSlice = createSlice({
  name: 'exam',
  initialState,

  extraReducers: {
    [getListExam.pending]: (state) => {
      state.loading = true;
    },
    [getListExam.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListExam.fulfilled]: (state, action) => {
      state.listExam = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },

    [getDetailsExam.pending]: (state) => {
      state.loading = true;
    },
    [getDetailsExam.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getDetailsExam.fulfilled]: (state, action) => {
      state.details = action.payload.data;
      state.loading = false;
      state.error = {};
    },

    [getListCreator.pending]: (state) => {
      state.loading = true;
    },
    [getListCreator.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListCreator.fulfilled]: (state, action) => {
      state.listCreator = action.payload.data;
      state.loading = false;
      state.error = {};
    },

    [getListSkills.pending]: (state) => {
      state.loading = true;
    },
    [getListSkills.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListSkills.fulfilled]: (state, action) => {
      state.listSkills = action.payload.data;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: examReducer } = examSlice;
export default examReducer;
