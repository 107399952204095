import { ROUTES, ROUTES_ADMIN } from 'routes/constants';
import iconDash from 'assets/icons/iconDash.svg';
import iconStaff from 'assets/icons/iconStaff.svg';
import iconLevel from 'assets/icons/iconLevel.svg';
import iconCourse from 'assets/icons/iconCourse.svg';
import iconTest from 'assets/icons/iconTest.svg';
import iconQuestion from 'assets/icons/iconQuestion.svg';
import iconLesson from 'assets/icons/iconLesson.svg';
import iconSkill from 'assets/icons/iconSkill.svg';
import iconScores from 'assets/icons/iconScores.svg';
import iconCalendar from 'assets/icons/iconCalendar.svg';
import iconSetting from 'assets/icons/iconSetting.svg';
import iconParts from 'assets/icons/iconParts.svg';
import iconStar from 'assets/icons/iconStar.svg';
import iconCatalog from 'assets/icons/iconCatalog.svg';

export const data = [
  {
    id: 'T1',
    title: 'TỔNG QUAN',
    menus: [
      {
        id: 0,
        link: ROUTES_ADMIN.OVERVIEW_PAGE,
        icon: iconDash,
        desc: 'Tổng quan',
        permission: 'dashboard',
        keyword: 'overview',
      },
    ],
  },
  {
    id: 'T2',
    title: 'QUẢN LÝ NGƯỜI DÙNG',
    menus: [
      {
        id: 3,
        link: ROUTES_ADMIN.LEVEL_PAGE,
        icon: iconLevel,
        desc: 'Cấp độ',
        permission: 'levels',
        keyword: 'level',
      },
      {
        id: 4,
        link: ROUTES_ADMIN.PARTS_MANAGEMENT,
        icon: iconParts,
        desc: 'Bộ Phận',
        permission: 'positions',
        keyword: 'parts',
      },
      {
        id: 1,
        link: ROUTES_ADMIN.EMPLOYEE_PAGE,
        icon: iconStaff,
        desc: 'Nhân viên',
        permission: 'users',
        keyword: 'employee',
      },
    ],
  },
  {
    id: 'T3',
    title: 'KHÓA HỌC',
    menus: [
      {
        id: 9,
        link: ROUTES_ADMIN.SKILL_MANAGEMENT,
        icon: iconSkill,
        desc: 'Quản lý kỹ năng',
        permission: 'manager_skills',
        keyword: 'skill-management',
      },
      {
        id: 7,
        link: ROUTES_ADMIN.QUESTION_MANAGEMENT,

        icon: iconQuestion,
        desc: 'Quản lý câu hỏi',
        permission: 'manager_lessons',
        keyword: 'question-management',
      },

      {
        id: 6,
        link: ROUTES_ADMIN.EXAM_MANAGEMENT,
        icon: iconTest,
        desc: 'Quản lý đề thi',
        permission: 'manager_exams',
        keyword: 'exam-management',
      },
      {
        id: 8,
        link: ROUTES_ADMIN.LESSON_MANAGEMENT,

        icon: iconLesson,
        desc: 'Quản lý bài học',
        permission: 'manager_questions',
        keyword: 'lesson-management',
      },
      {
        id: 15,
        link: ROUTES_ADMIN.COURSE_CATALOG,
        icon: iconCatalog,
        desc: 'Danh mục khóa học',
        permission: 'course_categories',
        keyword: 'course-catalog',
      },
      {
        id: 5,
        link: ROUTES_ADMIN.COURSE_MANAGEMENT,

        icon: iconCourse,
        desc: 'Quản lý khóa học',
        permission: 'manager_courses',
        keyword: 'course-management',
      },
    ],
  },
  {
    id: 'T4',
    title: 'QUẢN LÝ THÀNH TÍCH',
    menus: [
      {
        id: 10,
        link: ROUTES_ADMIN.ACHIEVEMENT_MANAGEMENT,
        icon: iconStar,
        desc: 'Quản lý thành tích',
        permission: 'manager_achievements',
        keyword: 'achievement-management',
      },
      {
        id: 11,
        link: ROUTES_ADMIN.SCORING_TEST,

        icon: iconScores,
        desc: 'Chấm điểm bài thi',
        permission: 'test_marks',
        keyword: 'scoring-test',
      },
      {
        id: 12,
        link: ROUTES_ADMIN.CALENDAR_PAGE,
        icon: iconCalendar,
        desc: 'Lịch học',
        permission: 'create_schedules',
        keyword: 'calendar',
      },
    ],
  },
  {
    id: 'T5',
    title: 'CÀI ĐẶT CHUNG',
    menus: [
      {
        id: 14,
        link: ROUTES_ADMIN.PROFILE_MANAGEMENT,
        icon: iconSetting,
        desc: 'Cài đặt',
        permission: 'all',
        keyword: 'profile-management',
      },
    ],
  },
];
