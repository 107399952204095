import {
  getListCourseApi,
  getCourseDetailApi,
  getListLessonByRouteApi,
  getDetailExamApi,
  getDetailLessonApi,
  getSubmittedTestApi,
  getListRatingApi,
} from 'services/apis/user/course';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getListCourse = createAsyncThunk(
  'course/GET_COURSE_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListCourseApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getCourseDetail = createAsyncThunk(
  'course/GET_COURSE_DETAIL',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getCourseDetailApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getDetailExamInLesson = createAsyncThunk(
  'course/GET_DETAIL_EXAM_IN_LESSON',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getDetailExamApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getDetailExam = createAsyncThunk(
  'course/GET_DETAIL_EXAM',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getDetailExamApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getDetailLesson = createAsyncThunk(
  'course/GET_DETAIL_LESSON',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getDetailLessonApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListLessonByRoute = createAsyncThunk(
  'course/GET_LIST_LESSON_BY_ROUTE',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getListLessonByRouteApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getSubmittedTest = createAsyncThunk(
  'course/GET_SUBMITTED_TEST',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getSubmittedTestApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListRating = createAsyncThunk(
  'course/GET_LIST_RATING_USER',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getListRatingApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  listCourse: [],
  detail: {},
  listLessonByRoute: [],
  detailExam: [],
  detailExamInLesson: [],
  detailLesson: [],
  listRating: [],
  submittedTest: {},
  paginate: {},
  loading: false,
  loadingDetail: false,
  error: {},
};

const courseSlice = createSlice({
  name: 'course',
  initialState,

  extraReducers: {
    [getListCourse.pending]: (state) => {
      state.loading = true;
    },
    [getListCourse.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListCourse.fulfilled]: (state, action) => {
      state.listCourse = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },

    [getCourseDetail.pending]: (state) => {
      state.loadingDetail = true;
    },
    [getCourseDetail.rejected]: (state, action) => {
      state.loadingDetail = false;
      state.error = action.payload?.message;
    },
    [getCourseDetail.fulfilled]: (state, action) => {
      state.detail = action.payload.data;
      state.loadingDetail = false;
      state.error = {};
    },

    [getListLessonByRoute.pending]: (state) => {
      state.loading = true;
    },
    [getListLessonByRoute.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListLessonByRoute.fulfilled]: (state, action) => {
      state.listLessonByRoute = action.payload.data;
      state.loading = false;
      state.error = {};
    },

    [getDetailExamInLesson.pending]: (state) => {
      state.loading = true;
    },
    [getDetailExamInLesson.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getDetailExamInLesson.fulfilled]: (state, action) => {
      state.detailExamInLesson = action.payload.data;
      state.loading = false;
      state.error = {};
    },

    [getDetailExam.pending]: (state) => {
      state.loading = true;
    },
    [getDetailExam.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getDetailExam.fulfilled]: (state, action) => {
      state.detailExam = action.payload.data;
      state.loading = false;
      state.error = {};
    },

    [getDetailLesson.pending]: (state) => {
      state.loading = true;
    },
    [getDetailLesson.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getDetailLesson.fulfilled]: (state, action) => {
      state.detailLesson = action.payload.data;
      state.loading = false;
      state.error = {};
    },

    [getSubmittedTest.pending]: (state) => {
      state.loading = true;
    },
    [getSubmittedTest.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getSubmittedTest.fulfilled]: (state, action) => {
      state.submittedTest = action.payload;
      state.loading = false;
      state.error = {};
    },

    [getListRating.pending]: (state) => {
      state.loading = true;
    },
    [getListRating.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListRating.fulfilled]: (state, action) => {
      state.listRating = action.payload.data;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: userCourseReducer } = courseSlice;
export default userCourseReducer;
