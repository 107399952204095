import { getListUserApi } from 'services/apis/admin/notification';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getListUser = createAsyncThunk(
  'notification/GET_LIST_USER',
  async (data, { rejectWithValue }) => {
    try {
      const response = await getListUserApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  listUser: [],
  loading: false,
  error: {},
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,

  extraReducers: {
    [getListUser.pending]: (state) => {
      state.loading = true;
    },
    [getListUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListUser.fulfilled]: (state, action) => {
      state.listUser = action.payload.data;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: notificationReducer } = notificationSlice;
export default notificationReducer;
