/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef } from 'react';
import OtpInput from 'react-otp-input';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import { forgotApi, resetPassV2Api, resetPassV3Api } from 'services/apis/other/auth';

import { Heading1, Heading2, Text } from 'components/commons/Typo';
import Input from 'components/Form/InputField';
import Button from 'components/commons/Button';
import ModalDetail from 'components/commons/ModalDetail';

import iconPass from '../../images/pass.svg';
import eye from '../../images/eye.svg';
import iconShow from '../../images/iconShow.svg';
import iconEmail from '../../images/email.svg';
import iconPhone from '../../images/phone.svg';

import { emailSchema, resetpassV2Schema } from '../../constants';

import * as S from './styled';

ForgotPassModal.propTypes = {
  show: PropTypes.bool,
};

function ForgotPassModal({ show, setShow }) {
  const refOtp1 = useRef(null);
  const refOtp2 = useRef(null);
  const refOtp3 = useRef(null);
  const refOtp4 = useRef(null);
  const [modal, setModal] = useState('modalEmail');
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [otp, setOtp] = useState('');
  const [countDown, setCountDown] = useState();
  const emailValidation = useForm({ resolver: yupResolver(emailSchema), mode: 'onChange' });
  const resetPassValidation = useForm({
    resolver: yupResolver(resetpassV2Schema),
    mode: 'onChange',
  });

  const resetForgotModal = () => {
    emailValidation.reset();
    resetPassValidation.reset();
    setOtp('');
    setModal('modalEmail');
    setShow(false);
  };

  const handleEmailSubmit = async (data) => {
    const res = await forgotApi(data);
    if (res.status === 200) {
      setModal('modalOtp');
      setCountDown(Date.now() + 4 * 60 * 1000);
    }
  };

  const handleOtpSubmit = async () => {
    const res = await resetPassV2Api({ code: otp, ...emailValidation.getValues() });
    if (res.status === 200) setModal('modalResetPass');
  };

  const handleOptInput = (oldRef, currentRef, nextRef) => {
    if (currentRef.current.value === '') oldRef.current.focus();
    if (currentRef.current?.value && !nextRef.current?.value) {
      nextRef.current.focus();
    }
    const data = [refOtp1, refOtp2, refOtp3, refOtp4].map((ref) => ref.current?.value).join('');
    setOtp(data);
  };

  const handleResetPass = async (data) => {
    const res = await resetPassV3Api({ ...data, ...emailValidation.getValues() });
    if (res.status === 200) resetForgotModal();
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      handleOtpSubmit();
    }
  };

  return (
    <ModalDetail visible={show} onCancel={() => resetForgotModal()} width="462px">
      {modal === 'modalEmail' && (
        <S.Wrapper>
          <Heading1 $color="#303841" textAlign="center" lineHeight="34px" margin="8px 0 32px">
            QUÊN MẬT KHẨU
          </Heading1>
          <Heading2
            style={{ fontWeight: 400, width: '300px' }}
            $color="#303841"
            textAlign="center"
            margin="0 auto 32px"
          >
            Vui lòng nhập địa chỉ email của bạn để nhận mã code xác thực
          </Heading2>
          <form onSubmit={emailValidation?.handleSubmit(handleEmailSubmit)}>
            <Input
              $height="52px"
              placeholder="Nhập mã nhân viên"
              label="Mã nhân viên"
              name="user_code"
              icon={iconPhone}
              register={emailValidation.register}
              error={emailValidation.formState.errors?.user_code?.message}
            />
            <Input
              $height="52px"
              placeholder="Nhập email"
              name="email"
              label="Email"
              icon={iconEmail}
              register={emailValidation?.register}
              error={emailValidation.formState?.errors?.email?.message}
            />
            <S.Footer>
              <Button $type="gray" borderRadius="8px" onClick={() => resetForgotModal()}>
                Quay lại
              </Button>
              <Button
                disabled={emailValidation.formState?.errors?.email?.message}
                type="submit"
                borderRadius="8px"
              >
                Tiếp tục
              </Button>
            </S.Footer>
          </form>
        </S.Wrapper>
      )}
      {modal === 'modalOtp' && (
        <S.Wrapper>
          <Heading1 $color="#303841" textAlign="center" lineHeight="34px" margin="8px 0 32px">
            XÁC THỰC EMAIL
          </Heading1>
          <Heading2
            style={{ fontWeight: 400, width: '360px' }}
            $color="#303841"
            textAlign="center"
            margin="0 auto 32px"
            lineHeight="24px"
          >
            Vui lòng nhập dãy số chúng tôi đã gửi qua email để tiếp tục
          </Heading2>
          <S.OtpInputBox>
            <S.OtpInput
              maxLength={1}
              ref={refOtp1}
              onChange={() => handleOptInput(refOtp1, refOtp1, refOtp2)}
            />
            <S.OtpInput
              maxLength={1}
              ref={refOtp2}
              onChange={() => handleOptInput(refOtp1, refOtp2, refOtp3)}
            />
            <S.OtpInput
              maxLength={1}
              ref={refOtp3}
              onChange={() => handleOptInput(refOtp2, refOtp3, refOtp4)}
            />
            <S.OtpInput
              maxLength={1}
              ref={refOtp4}
              onChange={() => handleOptInput(refOtp3, refOtp4, refOtp4)}
              onKeyPress={handleEnter}
            />
          </S.OtpInputBox>
          <Heading2
            style={{ fontWeight: 400 }}
            $color="#303841"
            textAlign="center"
            lineHeight="24px"
          >
            Vui lòng xác minh trong thời gian
          </Heading2>
          <Heading2
            style={{ fontWeight: 400 }}
            $color="#f8d000"
            textAlign="center"
            margin="0 auto 32px"
            lineHeight="24px"
          >
            <S.CountDownCustom value={countDown} />
          </Heading2>
          <S.Footer>
            <Button $type="gray" borderRadius="8px" onClick={() => setModal('modalEmail')}>
              Quay lại
            </Button>
            <Button
              disabled={otp.length !== 4}
              onClick={() => handleOtpSubmit()}
              borderRadius="8px"
            >
              Tiếp tục
            </Button>
          </S.Footer>

          <a onClick={() => handleEmailSubmit(emailValidation.getValues())}>
            <Heading2
              style={{ fontWeight: 400 }}
              $color="#f8d000"
              textAlign="center"
              margin="32px auto 0"
              lineHeight="24px"
            >
              Gửi lại mã
            </Heading2>
          </a>
        </S.Wrapper>
      )}
      {modal === 'modalResetPass' && (
        <S.Wrapper>
          <form onSubmit={resetPassValidation.handleSubmit(handleResetPass)}>
            <Heading1 $color="#303841" textAlign="center" lineHeight="34px" margin="8px 0 32px">
              ĐẶT LẠI MẬT KHẨU
            </Heading1>
            <Heading2
              style={{ fontWeight: 400, width: '360px' }}
              $color="#303841"
              textAlign="center"
              margin="0 auto 32px"
              lineHeight="24px"
            >
              Vui lòng đặt lại mật khẩu mới. Mật khẩu mới bắt buộc khác mật khẩu trước đó
            </Heading2>
            <Input
              $height="52px"
              icon={iconPass}
              iconPass={showPass ? iconShow : eye}
              placeholder="Nhập mật khẩu"
              name="password"
              label="Mật khẩu"
              type={showPass ? 'text' : 'password'}
              set_show={setShowPass}
              show={showPass}
              register={resetPassValidation.register}
              error={resetPassValidation.formState.errors?.password?.message}
            />
            <Input
              $height="52px"
              icon={iconPass}
              iconPass={showConfirmPass ? iconShow : eye}
              placeholder="Nhập lại mật khẩu"
              name="password_confirmation"
              label="Xác nhận mật khẩu"
              type={showConfirmPass ? 'text' : 'password'}
              set_show={setShowConfirmPass}
              show={showConfirmPass}
              register={resetPassValidation.register}
              error={resetPassValidation.formState.errors?.password_confirmation?.message}
            />
            <S.Footer>
              <Button $type="gray" borderRadius="8px" onClick={() => setModal('modalOtp')}>
                Quay lại
              </Button>
              <Button type="submit" borderRadius="8px">
                Tiếp tục
              </Button>
            </S.Footer>
          </form>
        </S.Wrapper>
      )}
    </ModalDetail>
  );
}

export default ForgotPassModal;
