import { getSchedulesApi } from 'services/apis/user/schedules';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getListSchedules = createAsyncThunk(
  'schedules/GET_SCHEDULES',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getSchedulesApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  listSchedules: [],
};

const schedulesSlice = createSlice({
  name: 'schedules',
  initialState,

  extraReducers: {
    [getListSchedules.pending]: (state) => {
      state.loading = true;
    },
    [getListSchedules.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListSchedules.fulfilled]: (state, action) => {
      state.listSchedules = action.payload.data;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: schedulesReducer } = schedulesSlice;
export default schedulesReducer;
