import {
  getListQuestionApi,
  getListSkillApi,
  getDetailsQuestionApi,
  getListCreatorApi,
} from 'services/apis/admin/questions';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getListQuestion = createAsyncThunk(
  'question/GET_LIST',
  async (data, { rejectWithValue }) => {
    try {
      const response = await getListQuestionApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListSkill = createAsyncThunk(
  'question/GET_LIST_SKILL',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListSkillApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListCreator = createAsyncThunk(
  'quesntion/GET_LIST_CREATOR',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListCreatorApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getDetailsQuestion = createAsyncThunk(
  'question/GET_DETAILS',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getDetailsQuestionApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  listQuestion: [],
  paginate: {},
  details: {},
  totalAmount: {},
  listSkill: [],
  listCreator: [],
  loading: false,
  error: {},
};

const questionSlice = createSlice({
  name: 'question',
  initialState,

  extraReducers: {
    [getListQuestion.pending]: (state) => {
      state.loading = true;
    },
    [getListQuestion.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListQuestion.fulfilled]: (state, action) => {
      state.listQuestion = action.payload.data;
      state.paginate = action.payload.paginate;
      state.totalAmount = action.payload.total_amount;
      state.loading = false;
      state.error = {};
    },

    [getListSkill.pending]: (state) => {
      state.loading = true;
    },
    [getListSkill.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListSkill.fulfilled]: (state, action) => {
      state.listSkill = action.payload.data;
      state.loading = false;
      state.error = {};
    },

    [getListCreator.pending]: (state) => {
      state.loading = true;
    },
    [getListCreator.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListCreator.fulfilled]: (state, action) => {
      state.listCreator = action.payload.data;
      state.loading = false;
      state.error = {};
    },

    [getDetailsQuestion.pending]: (state) => {
      state.loading = true;
    },
    [getDetailsQuestion.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getDetailsQuestion.fulfilled]: (state, action) => {
      state.details = action.payload.data;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: questionReducer } = questionSlice;
export default questionReducer;
