import request from 'services/request';

export const getListCourseActiveApi = async (params) =>
  request({
    url: '/admin/mark/list-course-active',
    method: 'GET',
    params,
  });

export const getListCourseCompleteApi = async (params) =>
  request({
    url: '/admin/mark/list-course-completed',
    method: 'GET',
    params,
  });

export const getListExamInCourseApi = async (id) =>
  request({
    url: `/admin/mark/list-exam/${id}`,
    method: 'GET',
  });

export const getListTestInExamApi = async (data) =>
  request({
    url: '/admin/mark/list-test',
    method: 'POST',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const getInfoExamApi = async (data) =>
  request({
    url: '/admin/mark/info-exam',
    method: 'POST',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const getDetailTestApi = async (id) =>
  request({
    url: `/admin/mark/detail-test/${id}`,
    method: 'GET',
  });

export const updateMarkTestPointApi = async (data) =>
  request({
    url: `/admin/mark`,
    method: 'POST',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const updateMarkStatusApi = async (id, data) =>
  request({
    url: `/admin/mark/lock-academic-transcript/${id}`,
    method: 'PUT',
    data,
  });
