import { getListUserApi, getDetailUserApi } from 'services/apis/admin/user';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getListUser = createAsyncThunk(
  'user/GET_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListUserApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getDetailsUser = createAsyncThunk(
  'user/GET_DETAILS',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getDetailUserApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  listUser: [],
  paginate: {},
  loading: false,
  error: {},
  details: {},
};

const userSlice = createSlice({
  name: 'user',
  initialState,

  extraReducers: {
    [getListUser.pending]: (state) => {
      state.loading = true;
    },
    [getListUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListUser.fulfilled]: (state, action) => {
      state.listUser = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },

    [getDetailsUser.pending]: (state) => {
      state.loading = true;
    },
    [getDetailsUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getDetailsUser.fulfilled]: (state, action) => {
      state.details = action.payload.data;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: userReducer } = userSlice;
export default userReducer;
