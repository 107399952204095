import request from 'services/request';

export const loginApi = async (data) =>
  request({
    url: '/user/signin',
    method: 'POST',
    data,
    enableFlashMessageError: false,
  });

export const getMeApi = async () =>
  request({
    url: '/user/me',
    method: 'GET',
    isAuth: true,
  });

export const updateProfileApi = async (data) =>
  request({
    url: '/update-profile',
    method: 'PUT',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });

export const resetPassApi = async (data) =>
  request({
    url: '/reset-password-profile',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });
export const forgotApi = async (data) =>
  request({
    url: '/forgot',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });
export const resetPassV2Api = async (data) =>
  request({
    url: '/reset-password-v2',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });
export const resetPassV3Api = async (data) =>
  request({
    url: '/reset-password-v3',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });
export const resetPassFirst = async (data) =>
  request({
    url: '/reset-password-first',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });

export const logoutApi = async () =>
  request({
    url: '/user/log-out',
    method: 'post',
    isAuth: true,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });
