import request from 'services/request';

export const getListLevelApi = async (params) =>
  request({
    url: '/admin/level/list',
    method: 'GET',
    params,
  });

export const createLevelApi = async (data) =>
  request({
    url: '/admin/level/create',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });

export const updateLevelApi = async (id, data) =>
  request({
    url: `/admin/level/update/${id}`,
    method: 'PUT',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const deleteLevelApi = async (id) =>
  request({
    url: `/admin/level/delete/${id}`,
    method: 'DELETE',
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });
