import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';

import ScrollToTop from 'hooks/ScrollToTop';
import AdminRoute from '../hocs/AdminRoute';
import NonAuthRoute from '../hocs/NonAuthRoute';
import AuthRoute from '../hocs/AuthRoute';

import { ROUTES, ROUTES_ADMIN, ROUTES_USER } from './constants';

import LoginPage from '../containers/OtherPage/LoginPage';

const CourseManagementPage = React.lazy(() =>
  import('../containers/AdminPage/CourseManagementPage')
);
const CreateQuestionPage = React.lazy(() => import('../containers/AdminPage/CreateQuestionPage'));
const EditQuestionPage = React.lazy(() => import('../containers/AdminPage/EditQuestionPage'));
const QuestionManagementPage = React.lazy(() =>
  import('../containers/AdminPage/QuestionManagementPage')
);
const CreateExamPage = React.lazy(() => import('../containers/AdminPage/CreateExamPage'));
const UpdateExamPage = React.lazy(() => import('../containers/AdminPage/UpdateExamPage'));
const ExamManagementPage = React.lazy(() => import('../containers/AdminPage/ExamManagementPage'));
const SkillManagementPage = React.lazy(() => import('../containers/AdminPage/SkillManagementPage'));
const ScoringTestPage = React.lazy(() => import('../containers/AdminPage/ScoringTestPage'));
const EmployeePage = React.lazy(() => import('../containers/AdminPage/EmployeePage'));
const CreateEmployeePage = React.lazy(() => import('../containers/AdminPage/CreateEmployeePage'));
const RankingScorePage = React.lazy(() => import('../containers/AdminPage/RankingScorePage'));
const LecturerPage = React.lazy(() => import('../containers/AdminPage/LecturerPage'));
const CreateLecturerPage = React.lazy(() => import('../containers/AdminPage/CreateLecturerPage'));
const OverviewPage = React.lazy(() => import('../containers/AdminPage/OverviewPage'));
const LevelPage = React.lazy(() => import('../containers/AdminPage/LevelPage'));
const PartsManagementPage = React.lazy(() => import('../containers/AdminPage/PartsManagementPage'));
const ScoringTestDetailPage = React.lazy(() =>
  import('../containers/AdminPage/ScoringTestDetailPage')
);
const LessonManagementPage = React.lazy(() =>
  import('../containers/AdminPage/LessonManagementPage')
);
const LessonDetailPage = React.lazy(() => import('../containers/AdminPage/LessonDetailPage'));
const CalenderPage = React.lazy(() => import('../containers/AdminPage/CalenderPage'));
const CourseManagementDetailPage = React.lazy(() =>
  import('../containers/AdminPage/CourseManagementPageDetail')
);
const ProfileManagement = React.lazy(() => import('../containers/AdminPage/ProfileManagement'));
const CreateCourse = React.lazy(() => import('../containers/AdminPage/CreateCoursePage'));
const EditCourse = React.lazy(() => import('../containers/AdminPage/EditCoursePage'));
const CreateLesson = React.lazy(() => import('../containers/AdminPage/CreateLessonPage'));

const UserCourseManagementPageDetail = React.lazy(() =>
  import('../containers/User/CourseManagementPageDetail')
);
const UpdateLesson = React.lazy(() => import('../containers/AdminPage/UpdateLessonPage'));
const AchievementManagement = React.lazy(() =>
  import('../containers/AdminPage/AchievementManagement')
);
const AchievementManagementDetail = React.lazy(() =>
  import('../containers/AdminPage/AchievementManagementDetail')
);
const ImportQuestion = React.lazy(() => import('../containers/AdminPage/ImportQuestionPage'));
const ImportEmployee = React.lazy(() => import('../containers/AdminPage/ImportEmployeePage'));
const TopStaff = React.lazy(() => import('../containers/AdminPage/TopStaff'));
const CourseCatalog = React.lazy(() => import('../containers/AdminPage/CourseCatalogPage'));
const CourseCatalogDetail = React.lazy(() => import('../containers/AdminPage/CourseCatalogDetail'));
const UserCourseManagementPage = React.lazy(() =>
  import('../containers/User/CourseManagementPage')
);
const OverviewUserPage = React.lazy(() => import('../containers/User/OverviewUser'));
const UpcomingClass = React.lazy(() => import('../containers/User/UpcomingClass'));
const NotificationPage = React.lazy(() => import('../containers/User/NotificationPage'));
const PolicyPage = React.lazy(() => import('../containers/User/PolicyPage'));

const Routes = () => {
  const info = useSelector((state) => state.authReducer.currentUser);

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          {/* None auth */}
          <NonAuthRoute exact path={ROUTES.LOGIN} component={LoginPage} />

          {/* Auth */}
          <AuthRoute
            exact
            path={ROUTES.HOME}
            component={info?.is_admin === 1 ? OverviewPage : OverviewUserPage}
          />
          <AuthRoute
            exact
            path={ROUTES_USER.COURSE_MANAGEMENT}
            component={UserCourseManagementPage}
          />
          <AuthRoute
            exact
            path={ROUTES_USER.COURSE_MANAGEMENT_DETAIL}
            component={UserCourseManagementPageDetail}
          />
          <AuthRoute exact path={ROUTES_USER.PROFILE_MANAGEMENT} component={ProfileManagement} />
          <AuthRoute exact path={ROUTES_USER.OVERVIEW_USER_PAGE} component={OverviewUserPage} />
          <AuthRoute exact path={ROUTES_USER.UPCOMING_CLASS} component={UpcomingClass} />
          <AuthRoute exact path={ROUTES_USER.NOTIFICATION_PAGE} component={NotificationPage} />
          <AuthRoute exact path={ROUTES_USER.POLICY_PAGE} component={PolicyPage} />
          {/* Admin */}
          <AdminRoute exact path={ROUTES_ADMIN.CREATE_QUESTION} component={CreateQuestionPage} />
          <AdminRoute exact path={ROUTES_ADMIN.EDIT_QUESTION} component={EditQuestionPage} />
          <AdminRoute
            exact
            path={ROUTES_ADMIN.COURSE_MANAGEMENT}
            component={CourseManagementPage}
          />
          <AdminRoute exact path={ROUTES_ADMIN.CREATE_EXAM} component={CreateExamPage} />
          <AdminRoute exact path={ROUTES_ADMIN.UPDATE_EXAM} component={UpdateExamPage} />
          <AdminRoute exact path={ROUTES_ADMIN.EXAM_MANAGEMENT} component={ExamManagementPage} />
          <AdminRoute exact path={ROUTES_ADMIN.SKILL_MANAGEMENT} component={SkillManagementPage} />
          <AdminRoute exact path={ROUTES_ADMIN.SCORING_TEST} component={ScoringTestPage} />
          <AdminRoute exact path={ROUTES_ADMIN.EMPLOYEE_PAGE} component={EmployeePage} />
          <AdminRoute exact path={ROUTES_ADMIN.LEVEL_PAGE} component={LevelPage} />
          <AdminRoute exact path={ROUTES_ADMIN.CREATE_EMPLOYEE} component={CreateEmployeePage} />
          <AdminRoute exact path={ROUTES_ADMIN.RANKING_SCORE} component={RankingScorePage} />
          <AdminRoute exact path={ROUTES_ADMIN.LECTURER_PAGE} component={LecturerPage} />
          <AdminRoute exact path={ROUTES_ADMIN.CREATE_LECTURER} component={CreateLecturerPage} />
          <AdminRoute exact path={ROUTES_ADMIN.OVERVIEW_PAGE} component={OverviewPage} />
          <AdminRoute
            exact
            path={ROUTES_ADMIN.QUESTION_MANAGEMENT}
            component={QuestionManagementPage}
          />
          <AdminRoute exact path={ROUTES_ADMIN.PARTS_MANAGEMENT} component={PartsManagementPage} />
          <AdminRoute
            exact
            path={ROUTES_ADMIN.SCORING_TEST_DETAIL}
            component={ScoringTestDetailPage}
          />
          <AdminRoute
            exact
            path={ROUTES_ADMIN.LESSON_MANAGEMENT}
            component={LessonManagementPage}
          />
          <AdminRoute exact path={ROUTES_ADMIN.LESSON_DETAIL} component={LessonDetailPage} />
          <AdminRoute exact path={ROUTES_ADMIN.CALENDAR_PAGE} component={CalenderPage} />
          <AdminRoute
            exact
            path={ROUTES_ADMIN.COURSE_MANAGEMENT_DETAIL}
            component={CourseManagementDetailPage}
          />
          <AdminRoute exact path={ROUTES_ADMIN.PROFILE_MANAGEMENT} component={ProfileManagement} />
          <AdminRoute exact path={ROUTES_ADMIN.CREATE_COURSE} component={CreateCourse} />
          <AdminRoute exact path={ROUTES_ADMIN.EDIT_COURSE} component={EditCourse} />
          <AdminRoute exact path={ROUTES_ADMIN.CREATE_LESSON} component={CreateLesson} />
          <AdminRoute exact path={ROUTES_ADMIN.UPDATE_LESSON} component={UpdateLesson} />
          <AdminRoute
            exact
            path={ROUTES_ADMIN.ACHIEVEMENT_MANAGEMENT}
            component={AchievementManagement}
          />
          <AdminRoute
            exact
            path={ROUTES_ADMIN.ACHIEVEMENT_MANAGEMENT_DETAIL}
            component={AchievementManagementDetail}
          />
          <AdminRoute exact path={ROUTES_ADMIN.TOP_STAFF} component={TopStaff} />
          <AdminRoute exact path={ROUTES_ADMIN.IMPORT_QUESTION} component={ImportQuestion} />
          <AdminRoute exact path={ROUTES_ADMIN.IMPORT_EMPLOYEE} component={ImportEmployee} />
          <AdminRoute exact path={ROUTES_ADMIN.COURSE_CATALOG} component={CourseCatalog} />
          <AdminRoute
            exact
            path={ROUTES_ADMIN.COURSE_CATALOG_DETAIL}
            component={CourseCatalogDetail}
          />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default Routes;
