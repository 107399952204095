import { getListSkillApi } from 'services/apis/admin/skill';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getListSkill = createAsyncThunk(
  'skill/GET_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListSkillApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  listSkill: [],
  paginate: {},
  loading: false,
  error: {},
};

const skillSlice = createSlice({
  name: 'skill',
  initialState,

  extraReducers: {
    [getListSkill.pending]: (state) => {
      state.loading = true;
    },
    [getListSkill.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListSkill.fulfilled]: (state, action) => {
      state.listSkill = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: skillReducer } = skillSlice;
export default skillReducer;
