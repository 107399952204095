import React from 'react';

import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'antd/dist/antd.css';
import { I18nextProvider } from 'react-i18next';
import i18n from 'services/i18n';
import Routes from './routes';
import GlobalStyle from './styles/globalStyle';
import theme from './styles/theme';
import 'react-toastify/dist/ReactToastify.css';
import { store } from './store';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-react-dropdowns/styles/material.css';
import '@syncfusion/ej2-react-navigations/styles/material.css';

function App() {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <Routes />
          <GlobalStyle />
          <ToastContainer position="top-center" hideProgressBar autoClose={5000} />
        </ThemeProvider>
      </I18nextProvider>
    </Provider>
  );
}

export default App;
