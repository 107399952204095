import request from 'services/request';

export const getListCategories = async (params) =>
  request({
    url: 'admin/categories/list',
    method: 'GET',
    params,
  });

export const createCategories = async (data) =>
  request({
    url: 'admin/categories/create',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });

export const deleteCatalogApi = async (id) =>
  request({
    url: `/admin/categories/delete/${id}`,
    method: 'DELETE',
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const updateCatalogApi = async (id, data) =>
  request({
    url: `/admin/categories/update/${id}`,
    method: 'PUT',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const getListCategoriesDetailApi = async (params) =>
  request({
    url: `admin/categories/list-course/${params?.id}`,
    method: 'GET',
    params,
  });

export const deleteCategoriesDetailgApi = async (id) =>
  request({
    url: `admin/categories/delete/${id}`,
    method: 'DELETE',
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const deleteCourseFromCategoriesApi = async (id) =>
  request({
    url: `admin/categories/delete-category-course/${id}`,
    method: 'PUT',
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });
