import { ROUTES, ROUTES_USER } from 'routes/constants';
import iconDash from 'assets/icons/iconDash.svg';
import iconStaff from 'assets/icons/iconStaff.svg';
import iconMentor from 'assets/icons/iconMentor.svg';
import iconCourse from 'assets/icons/iconCourse.svg';
import iconTest from 'assets/icons/iconTest.svg';
import iconQuestion from 'assets/icons/iconQuestion.svg';
import iconScores from 'assets/icons/iconScores.svg';
import iconCalendar from 'assets/icons/iconCalendar.svg';
import iconSchedule from 'assets/icons/iconSchedule.svg';
import iconWorking from 'assets/icons/iconWorking.svg';
import iconInfo from 'assets/icons/iconInfo.svg';

export const user_data = [
  {
    id: 'T1',
    title: 'TỔNG QUAN',
    menus: [
      {
        id: 0,
        link: ROUTES_USER.OVERVIEW_USER_PAGE,
        icon: iconDash,
        desc: 'Tổng quan',
        keyword: 'overview',
      },
    ],
  },
  {
    id: 'T2',
    title: 'KHÓA HỌC',
    menus: [
      {
        id: 1,
        link: ROUTES_USER.COURSE_MANAGEMENT,
        icon: iconCourse,
        desc: 'Danh sách khoá học',
        keyword: 'course-management',
      },
      // {
      //   id: 2,
      //   link: '',
      //   icon: iconTest,
      //   desc: 'Danh sách bài học',
      // },
      // {
      //   id: 3,
      //   link: '',
      //   icon: iconWorking,
      //   desc: 'Giải bài tập',
      // },
      // {
      //   id: 4,
      //   link: '',
      //   icon: iconQuestion,
      //   desc: 'Quản lý bài học',
      // },
      {
        id: 5,
        link: ROUTES_USER.UPCOMING_CLASS,
        icon: iconCalendar,
        desc: 'Lịch học sắp tới',
        keyword: 'upcoming-class',
      },
    ],
  },
  // {
  //   id: 'T3',
  //   title: 'QUẢN LÝ THÀNH TÍCH',
  //   menus: [
  //     {
  //       id: 7,
  //       link: '',
  //       icon: iconScores,
  //       desc: 'Xem kết quả học tập',
  //     },
  //     // {
  //     //   id: 8,
  //     //   link: '',
  //     //   icon: iconCalendar,
  //     //   desc: 'Theo dõi điểm số - tra điểm',
  //     // },
  //   ],
  // },
  {
    id: 'T4',
    title: 'HỒ SƠ CÁ NHÂN',
    menus: [
      {
        id: 10,
        link: ROUTES_USER.PROFILE_MANAGEMENT,
        icon: iconStaff,
        desc: 'Hồ sơ cá nhân',
        keyword: 'profile-management',
      },
      {
        id: 11,
        link: ROUTES_USER.POLICY_PAGE,
        icon: iconInfo,
        desc: 'Chính sách người dùng',
        keyword: 'policy',
      },
    ],
  },
];
