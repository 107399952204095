import request from 'services/request';

export const getListUserApi = async (params) =>
  request({
    url: '/admin/users/list-user',
    method: 'GET',
    params,
  });

export const getDetailUserApi = async (id) =>
  request({
    url: `/admin/users/detail-user/${id}`,
    method: 'GET',
  });

export const createUserApi = async (data) =>
  request({
    url: '/admin/users/create-user',
    method: 'POST',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const updateUserApi = async (id, data) =>
  request({
    url: `/admin/users/update-user/${id}`,
    method: 'PUT',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const deleteUserApi = async (data) =>
  request({
    url: `/admin/users/delete`,
    method: 'DELETE',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const resetPasswordApi = async (id) =>
  request({
    url: `/admin/users/reset-password/${id}`,
    method: 'POST',
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });
