import {
  getListLessonApi,
  getListTeacherApi,
  getListTutorApi,
  getDetailsLessonApi,
} from 'services/apis/admin/lesson';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getListLesson = createAsyncThunk(
  'lesson/GET_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListLessonApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListTeacher = createAsyncThunk(
  'lesson/GET_LIST_TEACHER',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListTeacherApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListTutor = createAsyncThunk(
  'lesson/GET_LIST_TUTOR',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListTutorApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getDetailsLesson = createAsyncThunk(
  'lesson/GET_DETAILS',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getDetailsLessonApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  listLesson: [],
  listTeacher: [],
  listTutor: [],
  details: {},
  paginate: {},
  loading: false,
  error: {},
};

const lessonSlice = createSlice({
  name: 'lesson',
  initialState,

  extraReducers: {
    [getListLesson.pending]: (state) => {
      state.loading = true;
    },
    [getListLesson.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListLesson.fulfilled]: (state, action) => {
      state.listLesson = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },

    [getListTeacher.pending]: (state) => {
      state.loading = true;
    },
    [getListTeacher.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListTeacher.fulfilled]: (state, action) => {
      state.listTeacher = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },

    [getListTutor.pending]: (state) => {
      state.loading = true;
    },
    [getListTutor.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListTutor.fulfilled]: (state, action) => {
      state.listTutor = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },

    [getDetailsLesson.pending]: (state) => {
      state.loading = true;
    },
    [getDetailsLesson.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getDetailsLesson.fulfilled]: (state, action) => {
      state.details = action.payload.data;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: lessonReducer } = lessonSlice;
export default lessonReducer;
