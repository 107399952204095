import request from 'services/request';

export const getListAdminApi = async (params) =>
  request({
    url: '/admin/users/list-user/1',
    method: 'GET',
    params,
  });

export const getDetailAdminApi = async (id) =>
  request({
    url: `/admin/users/detail-user/${id}`,
    method: 'GET',
  });

export const createAdminApi = async (data) =>
  request({
    url: '/admin/users/create-admin',
    method: 'POST',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const updateAdminApi = async (id, data) =>
  request({
    url: `/admin/users/update-admin/${id}`,
    method: 'PUT',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const deleteAdminApi = async (id) =>
  request({
    url: `/admin/users/delete/${id}`,
    method: 'DELETE',
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });
