import { getListDomainApi, getListStoreApi, getListPositionApi, getPositionDetailApi } from 'services/apis/admin/parts';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getListDomain = createAsyncThunk(
  'parts/GET_LIST_DOMAIN',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getListDomainApi();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListStore = createAsyncThunk(
  'parts/GET_LIST_STORE',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListStoreApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListPosition = createAsyncThunk(
  'parts/GET_LIST_POSITION',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListPositionApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getPositionDetail = createAsyncThunk(
  'parts/GET_POSITION_DETAIL',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getPositionDetailApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  listDomain: [],
  listStore: [],
  listPosition: [],
  positionDetail: {},
  paginate: {},
  loading: false,
  error: {},
};

const partsSlice = createSlice({
  name: 'parts',
  initialState,

  extraReducers: {
    [getListDomain.pending]: (state) => {
      state.loading = true;
    },
    [getListDomain.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListDomain.fulfilled]: (state, action) => {
      state.listDomain = action.payload.data;
      state.loading = false;
      state.error = {};
    },

    [getListStore.pending]: (state) => {
      state.loading = true;
    },
    [getListStore.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListStore.fulfilled]: (state, action) => {
      state.listStore = action.payload.data;
      state.loading = false;
      state.error = {};
    },

    [getListPosition.pending]: (state) => {
      state.loading = true;
    },
    [getListPosition.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListPosition.fulfilled]: (state, action) => {
      state.listPosition = action.payload.data;
      state.loading = false;
      state.error = {};
    },

    [getPositionDetail.pending]: (state) => {
      state.loading = true;
    },
    [getPositionDetail.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getPositionDetail.fulfilled]: (state, action) => {
      state.positionDetail = action.payload.data;
      state.loading = false;
      state.error = {};
    },

  },
});

const { reducer: partsReducer } = partsSlice;
export default partsReducer;
