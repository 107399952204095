import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import { login } from 'store/other/authSlice';
import { getTokenFirebase } from 'services/firebaseInit';

import Input from 'components/Form/InputField';
import Button from 'components/commons/Button';
import logo from 'assets/icons/logo.svg';
import ForgotPassModal from './components/ForgotPassModal';

import iconPhone from './images/phone.svg';
import iconPass from './images/pass.svg';
import eye from './images/eye.svg';
import iconShow from './images/iconShow.svg';
import iconErr from './images/passErr.svg';

import Schema from './constants';
import * as S from './styled';

function LoginPage() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [savePass, setSavePass] = useState(false);
  const [token, setToken] = useState(null);
  const errLogin = useSelector((state) => state.authReducer.error);
  const loading = useSelector((state) => state.authReducer.loading);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(Schema), mode: 'onChange' });

  useEffect(() => {
    if (localStorage.getItem('user_code')) {
      setValue('user_code', localStorage.getItem('user_code'));
      setValue('password', localStorage.getItem('password'));
      setSavePass(true);
    }
  }, []);

  useEffect(() => {
    notifyMe();
  }, []);

  useEffect(() => {
    getTokenFirebase()
      .then((value) => setToken(value))
      .catch((err) => console.log('ERR', err));
  }, []);

  const onSubmit = async (data) => {
    if (token) {
      data.device_token = token;
    }
    dispatch(login({ ...data, isRemember: savePass }));
    if (savePass) {
      if (errLogin?.length === 0) {
        localStorage.setItem('user_code', data.user_code);
        localStorage.setItem('password', data.password);
      } else {
        localStorage.clear();
      }
    } else {
      localStorage.clear();
    }
  };

  const notifyMe = () => {
    if (!('Notification' in window)) {
      alert('Your browser does not support desktop notification');
    } else if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
    } else {
      Notification.requestPermission();
    }
  };

  return (
    <S.Wrapper>
      <S.Header>
        <img src={logo} alt=" " />
        <h1>HỆ THỐNG ĐÀO TẠO NỘI BỘ</h1>
      </S.Header>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.Title>ĐĂNG NHẬP</S.Title>
        <S.Label>Mã nhân viên</S.Label>
        <Input
          $height="52px"
          placeholder="Nhập mã nhân viên"
          name="user_code"
          icon={iconPhone}
          register={register}
          error={errors?.user_code?.message}
        />
        <S.Label>Mật khẩu</S.Label>
        <Input
          $height="52px"
          icon={iconPass}
          iconPass={show ? iconShow : eye}
          placeholder="Nhập mật khẩu"
          name="password"
          type={show ? 'text' : 'password'}
          set_show={setShow}
          show={show}
          error={errors?.password?.message}
          register={register}
        />
        <S.ForgotPass>
          <S.BoxRadio>
            <S.RadioCustom checked={savePass} setChecked={setSavePass} />
            <p>Ghi nhớ đăng nhập</p>
          </S.BoxRadio>

          <button type="button" onClick={() => setShowModal(true)}>
            Quên mật khẩu?
          </button>
        </S.ForgotPass>
        {errLogin?.length && !loading && (
          <S.ErrorMessage>
            <S.SVG $width="22px" src={iconErr}></S.SVG>
            <span>Tài khoản hoặc mật khẩu không đúng, vui lòng thử lại</span>
          </S.ErrorMessage>
        )}
        <Button type="submit" width="100%">
          ĐĂNG NHẬP
        </Button>
      </S.Form>
      <ForgotPassModal show={showModal} setShow={setShowModal}></ForgotPassModal>
    </S.Wrapper>
  );
}

export default LoginPage;
