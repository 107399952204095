import request from 'services/request';

export const getListQuestionApi = async (data) =>
  request({
    url: '/admin/questions/list',
    method: 'POST',
    data,
  });

export const getListSkillApi = async (params) =>
  request({
    url: '/admin/questions/listSkills',
    method: 'GET',
    params,
  });

export const getListCreatorApi = async (params) =>
  request({
    url: '/admin/questions/listCreatedBy',
    method: 'GET',
    params,
  });

export const createQuestionApi = async (data) =>
  request({
    url: '/admin/questions/create',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });

export const deleteQuestionApi = async (id) =>
  request({
    url: `/admin/questions/delete/${id}`,
    method: 'DELETE',
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const getDetailsQuestionApi = async (id) =>
  request({
    url: `/admin/questions/detail/${id}`,
    method: 'GET',
  });

export const updateQuestionApi = async (id, data) =>
  request({
    url: `/admin/questions/update/${id}`,
    method: 'PUT',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });
