import request from 'services/request';

export const getListCourseApi = async (params) =>
  request({
    url: '/admin/courses',
    method: 'GET',
    params,
  });

export const getListCreatorApi = async (params) =>
  request({
    url: '/admin/courses/list-created-by',
    method: 'GET',
    params,
  });

export const getListUserApi = async (data) =>
  request({
    url: '/admin/courses/list-user',
    method: 'POST',
    data,
  });

export const getListCourseDetailApi = async (id) =>
  request({
    url: `/admin/courses/detail/${id}`,
    method: 'GET',
  });

export const getListCategoriesApi = async (params) =>
  request({
    url: '/admin/categories/list',
    method: 'GET',
    params,
  });

export const getListEmployeeJoinApi = async (params) =>
  request({
    url: 'admin/courses/employee-join',
    method: 'GET',
    params,
  });

export const getListEmployeeCompleteApi = async (params) =>
  request({
    url: 'admin/courses/employee-complete',
    method: 'GET',
    params,
  });

export const createCourseApi = async (data) =>
  request({
    url: '/admin/courses/create',
    method: 'POST',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const editCourseApi = async (id, data) =>
  request({
    url: `/admin/courses/update/${id}`,
    method: 'PUT',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const deleteCourseApi = async (id) =>
  request({
    url: `/admin/courses/delete/${id}`,
    method: 'DELETE',
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const addUserToCourseApi = async (data) =>
  request({
    url: '/admin/courses/add-employee',
    method: 'POST',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const getListRatingApi = async (id) =>
  request({
    url: `/admin/courses/ratings/${id}`,
    method: 'GET',
  });
