import {
  getListOverviewApi,
  getListTopStaffApi,
  getListTopStaffsApi,
  getChartDataApi,
} from 'services/apis/admin/overview';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getListOverview = createAsyncThunk(
  'overview/GET_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListOverviewApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListTopStaff = createAsyncThunk(
  'overview/GET_LIST_TOP',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListTopStaffApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListTopStaffs = createAsyncThunk(
  'overview/GET_LIST_TOPS',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListTopStaffsApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getChartData = createAsyncThunk(
  'overview/CHART_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getChartDataApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  listOverview: [],
  listTopStaff: [],
  listTopStaffs: [],
  chartData: [],
  paginate: {},
  loading: false,
  error: {},
};

const overviewSlice = createSlice({
  name: 'overview',
  initialState,

  extraReducers: {
    [getListOverview.pending]: (state) => {
      state.loading = true;
    },
    [getListOverview.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListOverview.fulfilled]: (state, action) => {
      state.listOverview = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },

    [getListTopStaff.pending]: (state) => {
      state.loading = true;
    },
    [getListTopStaff.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListTopStaff.fulfilled]: (state, action) => {
      state.listTopStaff = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },

    [getListTopStaffs.pending]: (state) => {
      state.loading = true;
    },
    [getListTopStaffs.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListTopStaffs.fulfilled]: (state, action) => {
      state.listTopStaffs = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },

    [getChartData.pending]: (state) => {
      state.loading = true;
    },
    [getChartData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getChartData.fulfilled]: (state, action) => {
      state.chartData = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: overviewReducer } = overviewSlice;
export default overviewReducer;
