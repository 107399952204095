import styled, { css } from 'styled-components';
import media from 'styles/breakpoints';
import { Layout as La } from 'antd';

const { Header, Content, Sider } = La;

export const Layout = styled(La)`
  min-height: 100vh;
  background: #e5e5e5;
`;

export const Hd = styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 10;
  width: 100%;
  &.ant-layout-header {
    height: 70px;
    background: #ffffff !important;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.06) !important;
  }
`;

export const Sd = styled(Sider)`
  padding: 32px 0 32px 24px;
  position: sticky;
  margin-top: 70px;
  top: 70px;
  height: calc(100vh + 200px);
  overflow: auto;
  &.menu-user {
    height: calc(100vh - 70px);
  }
  ${media.fmDesktop`
    height: calc(100vh + 420px)
  `}
  ${({ showTab }) =>
    showTab &&
    css`
      padding: 32px 0 32px 4px;
    `};

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Contents = styled(Content)`
  padding: 16px 24px;
  background: #f8f8f8 !important;
  margin-top: 70px;
`;

export const WrapParent = styled.div`
  display: flex !important;
  flex-direction: column !important;
  margin-bottom: 40px;
  ${({ showTab }) =>
    showTab &&
    css`
      margin-bottom: 15px;
      padding-bottom: 15px;
    `};
`;

export const Parent = styled.div`
  color: #ffb71c;
  font-size: 12px;
  font-weight: 600;
  ${({ showTab }) =>
    showTab &&
    css`
      display: none;
    `}
`;

export const WrapChild = styled.div`
  display: flex !important;
  flex-direction: column !important;
  padding-left: 18px;
  padding-top: 20px;
`;

export const Child = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0px 10px 10px;
  margin-bottom: 10px;
  cursor: pointer;
  ${({ active }) =>
    active &&
    css`
      background: #ffffff;
      border-radius: 22px 0px 0px 22px;
      color: #2c2c2c;
    `}
`;

export const DFlex = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;
export const LogOut = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #868a96;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 70%;
  background: #28283f;
  border-radius: 25.5px;
  cursor: pointer;
  gap: 0 12px;
`;
export const WrapLogOut = styled.div`
  display: flex !important;
  flex-direction: column !important;
  padding-left: 18px;
`;

export const WrapBell = styled.div`
  position: relative;
  cursor: pointer;

  .noti-qty {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #f8d000;
    border: 2px solid #fff;
    z-index: 3;
    position: absolute;
    top: 20px;
    right: -4px;
    text-align: center;
  }

  .qty {
    color: #fff;
    font-size: 9px;
    font-weight: 500;
    position: absolute;
    height: 100%;
    top: -4px;
    right: 0px;
    z-index: 4;
  }
`;

export const SwitchView = styled.div`
  width: 236px;
  padding: 8px 16px;
  border-radius: 8px;
  background: #f8d000;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  display: flex;
  gap: 8px;
  justify-content: center;
  cursor: pointer;
`;
