import request from 'services/request';

export const getListUserApi = async (data) =>
  request({
    url: '/admin/notifications/list-user',
    method: 'POST',
    data,
  });

export const sendNotificationApi = async (data) =>
  request({
    url: 'admin/notifications/send',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });
