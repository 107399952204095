import request from 'services/request';

export const getListSkillApi = async (params) =>
  request({
    url: '/admin/skills/list',
    method: 'GET',
    params,
  });

export const createSkillApi = async (data) =>
  request({
    url: '/admin/skills/create',
    method: 'POST',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const deleteSkillApi = async (id) =>
  request({
    url: `/admin/skills/delete/${id}`,
    method: 'DELETE',
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true
  });

export const updateSkillApi = async (id, data) =>
  request({
    url: `/admin/skills/update/${id}`,
    method: 'PUT',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });
