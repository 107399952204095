import { loginApi, getMeApi, logoutApi } from 'services/apis/other/auth';
import webStorage from 'utils/webStorage';
import { ROUTES } from 'routes/constants';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const login = createAsyncThunk('auth/LOGIN', async (data, { rejectWithValue }) => {
  try {
    const res = await loginApi(data);
    return { ...res.data, isRemember: data.isRemember };
  } catch (error) {
    return rejectWithValue(error.data);
  }
});

export const getMe = createAsyncThunk('auth/GET_ME', async () => {
  const response = await getMeApi();
  return response.data;
});
export const logOut = createAsyncThunk('auth/LOG_OUT', async () => {
  const response = await logoutApi();
  return response;
});

const initialState = {
  currentUser: {},
  isAuth: false,
  isFirstAccess: true,
  loading: false,
  error: {},
};

const authSlice = createSlice({
  name: 'auth',
  initialState,

  reducers: {
    updateIsFirstAccess: (state) => {
      state.isFirstAccess = false;
    },

    resetState: (state, action) => {
      const fieldName = action.payload;
      state[fieldName] = initialState[fieldName];
    },
  },

  extraReducers: {
    [login.pending]: (state) => {
      state.loading = true;
    },
    [login.rejected]: (state, action) => {
      state.loading = false;
      state.isFirstAccess = false;
      state.error = action.payload?.message;
    },
    [login.fulfilled]: (state, action) => {
      const { isRemember } = action.payload;
      state.isAuth = true;
      state.isFirstAccess = true;
      state.currentUser = action.payload.data;
      state.loading = false;
      state.error = {};
      webStorage.setToken(action.payload.data.access_token, isRemember ? { expires: 30 } : {});
    },

    [getMe.pending]: (state) => {
      state.loading = true;
    },
    [getMe.rejected]: (state) => {
      state.loading = false;
      state.isAuth = false;
      state.isFirstAccess = false;
    },
    [getMe.fulfilled]: (state, action) => {
      state.isAuth = true;
      state.isFirstAccess = false;
      state.currentUser = action.payload.data;
      state.loading = false;
    },

    [logOut.pending]: (state) => {
      state.loading = true;
    },
    [logOut.rejected]: (state, action) => {
      state.loading = false;
      state.isFirstAccess = false;
      state.error = action.payload?.message;
    },
    [logOut.fulfilled]: (state) => {
      state.isAuth = false;
      state.isFirstAccess = false;
      state.currentUser = null;
      state.loading = false;
      state.error = {};
      webStorage.removeAll();
      window.location.replace(ROUTES.LOGIN);
    },
  },
});

export const { updateIsFirstAccess, setAuth } = authSlice.actions;
const { reducer: authReducer } = authSlice;
export default authReducer;
