import styled from 'styled-components';
import { Statistic } from 'antd';

export const Wrapper = styled.div`
  padding: 8px 16px;
  label {
    font-size: 12px;
  }
  a {
    text-decoration: underline;
    color: #f8d000;
  }
`;

export const Footer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  gap: 24px;
  button {
    flex-grow: 1;
  }
`;

export const OtpInputBox = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
`;

export const OtpInput = styled.input`
  width: 61px;
  height: 70px;
  border: 1px solid #dce0ea;
  border-radius: 16px;
  font-size: 32px;
  line-height: 42px;
  font-weight: 500;
  color: #0a0822;
  margin: 0 4px;
  text-align: center;
`;

export const CountDownCustom = styled(Statistic.Countdown)`
  .ant-statistic-content-value {
    font-size: 16px; 
    line-height: 24px;
    font-weight: 400;
    color: #f8d000;
  }
`
