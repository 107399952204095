import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getMe, updateIsFirstAccess } from 'store/other/authSlice';

import Loading from 'components/Loading';

import { ROUTES_ADMIN, ROUTES_USER } from 'routes/constants';

import PropTypes from 'prop-types';
import webStorage from 'utils/webStorage';
import FirstLogin from '../containers/OtherPage/FirstLogin';

NonAuthRoute.propTypes = {
  component: PropTypes.any,
};

function NonAuthRoute({ component: Component, ...rest }) {
  const dispatch = useDispatch();
  const isFirstAccess = useSelector((state) => state.authReducer.isFirstAccess);
  const currentUser = useSelector((state) => state.authReducer.currentUser);
  const { isAuth, isSignUpSuccess } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (isFirstAccess) {
      const accessToken = webStorage.getToken();
      if (accessToken) {
        dispatch(getMe());
      } else {
        dispatch(updateIsFirstAccess());
      }
    }
  }, [dispatch, isFirstAccess]);

  // waiting for check when user is first access by url (for example: direct access, F5)
  if (isFirstAccess) return <Loading />;
  // redirect to top page, prevent access to non auth route (for example: /login) when user is authenticated
  if (currentUser.ischangepass === 0) return <FirstLogin />;
  if (isAuth && !isSignUpSuccess) {
    return (
      <Redirect
        to={
          currentUser?.is_admin === 1 || currentUser?.admin_layout === 1
            ? ROUTES_ADMIN.OVERVIEW_PAGE
            : ROUTES_USER.OVERVIEW_USER_PAGE
        }
      />
    );
  }

  return (
    <Route {...rest}>
      <Component />
    </Route>
  );
}

export default NonAuthRoute;
