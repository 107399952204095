import { getListCategories, getListCategoriesDetailApi } from 'services/apis/admin/catalog';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getListCatalog = createAsyncThunk(
  'catalog/GET_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListCategories(params ?? {});
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const getListCatalogDetail = createAsyncThunk(
  'catalog/GET_LIST_DETAIL',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListCategoriesDetailApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
const initialState = {
  listCatalog: [],
  detail: [],
  paginate: {},
  loading: false,
  error: {},
};

const catalogSlice = createSlice({
  name: 'catalog',
  initialState,

  extraReducers: {
    [getListCatalog.pending]: (state) => {
      state.loading = true;
    },
    [getListCatalog.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListCatalog.fulfilled]: (state, action) => {
      state.listCatalog = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },
    [getListCatalogDetail.pending]: (state) => {
      state.loading = true;
    },
    [getListCatalogDetail.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListCatalogDetail.fulfilled]: (state, action) => {
      state.detail = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: catalogReducer } = catalogSlice;
export default catalogReducer;
