import request from 'services/request';

export const getListDomainApi = async () =>
  request({
    url: '/admin/domain/list',
    method: 'GET',
  });

export const createDomainApi = async (data) =>
  request({
    url: '/admin/domain/create',
    method: 'POST',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const updateDomainApi = async (id, data) =>
  request({
    url: `/admin/domain/update/${id}`,
    method: 'PUT',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const getListStoreApi = async (params) =>
  request({
    url: '/admin/storepart/list',
    method: 'GET',
    params,
  });

export const getListPositionApi = async (params) =>
  request({
    url: '/admin/position/list',
    method: 'GET',
    params,
  });

export const getPositionDetailApi = async (id) =>
  request({
    url: `/admin/position/detail/${id}`,
    method: 'GET',
  });

export const createStoreApi = async (data) =>
  request({
    url: '/admin/storepart/create',
    method: 'POST',
    data,
  });

export const createPositionApi = async (data) =>
  request({
    url: '/admin/position/create',
    method: 'POST',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

  export const updatePositionApi = async (id, data) =>
  request({
    url: `/admin/position/update/${id}`,
    method: 'PUT',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

  export const deletePositionApi = async (data) =>
  request({
    url: '/admin/position/delete',
    method: 'DELETE',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });