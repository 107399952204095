import { getListAdminApi, getDetailAdminApi } from 'services/apis/admin/admin';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getListAdmin = createAsyncThunk(
  'admin/GET_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListAdminApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getDetailsAdmin = createAsyncThunk(
  'admin/GET_DETAILS',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getDetailAdminApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  listAdmin: [],
  paginate: {},
  loading: false,
  error: {},
  details: {},
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,

  extraReducers: {
    [getListAdmin.pending]: (state) => {
      state.loading = true;
    },
    [getListAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListAdmin.fulfilled]: (state, action) => {
      state.listAdmin = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },

    [getDetailsAdmin.pending]: (state) => {
      state.loading = true;
    },
    [getDetailsAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getDetailsAdmin.fulfilled]: (state, action) => {
      state.details = action.payload.data;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: adminReducer } = adminSlice;
export default adminReducer;
