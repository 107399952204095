import {
  getListOverviewApi,
  getListOverviewstatisticsApi,
  getListOverviewschedulesApi,
  getListNotificationApi,
  getChartDataApi,
} from 'services/apis/user/overview';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getListOverview = createAsyncThunk(
  'overview/GET_OVERVIEW_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListOverviewApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListOverviewStatis = createAsyncThunk(
  'overview/GET_OVERVIEW_STATIS_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListOverviewstatisticsApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListOverviewschedules = createAsyncThunk(
  'overview/GET_OVERVIEW_SCHEDULES_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListOverviewschedulesApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListNotification = createAsyncThunk(
  'overview/GET_LIST_NOTIFICATION',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListNotificationApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getChartData = createAsyncThunk(
  'overview/CHART_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getChartDataApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  listOverview: [],
  listOverviewStatis: [],
  listOverviewSchedules: [],
  listNotification: [],
  unread: '',
  detail: [],
  chartData: [],
  paginate: {},
  paginateNotification: {},
  loading: false,
  error: {},
};

const userOverviewSlice = createSlice({
  name: 'userOverview',
  initialState,

  extraReducers: {
    [getListOverview.pending]: (state) => {
      state.loading = true;
    },
    [getListOverview.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListOverview.fulfilled]: (state, action) => {
      state.listOverview = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },

    [getListOverviewStatis.pending]: (state) => {
      state.loading = true;
    },
    [getListOverviewStatis.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListOverviewStatis.fulfilled]: (state, action) => {
      state.listOverviewStatis = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },

    [getListOverviewschedules.pending]: (state) => {
      state.loading = true;
    },
    [getListOverviewschedules.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListOverviewschedules.fulfilled]: (state, action) => {
      state.listOverviewSchedules = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },

    [getListNotification.pending]: (state) => {
      state.loading = true;
    },
    [getListNotification.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListNotification.fulfilled]: (state, action) => {
      state.listNotification = action.payload.data;
      state.paginateNotification = action.payload.paginate;
      state.unread = action.payload.total_unread;
      state.loading = false;
      state.error = {};
    },

    [getChartData.pending]: (state) => {
      state.loading = true;
    },
    [getChartData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getChartData.fulfilled]: (state, action) => {
      state.chartData = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: userOverviewReducer } = userOverviewSlice;
export default userOverviewReducer;
