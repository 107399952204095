import request from 'services/request';

export const getListAchievementsApi = async (data) =>
  request({
    url: 'admin/achievements/users-with-positions',
    method: 'POST',
    data,
  });

export const getListAchievementUserApi = async (params) =>
  request({
    url: 'admin/achievements?period=month',
    method: 'GET',
    params,
  });
export const getListAchievementUserApiDetail = async (params, id) =>
  request({
    url: `admin/achievements/users/${id}/courses-required-not-join`,
    method: 'GET',
    params,
  });
export const getListAchievementPersonApi = async (id) =>
  request({
    url: `admin/achievements/users-with-positions-no-permision/${id}`,
    method: 'GET',
  });
export const getListAchievementCourseJoinApi = async (params, id) =>
  request({
    url: `admin/achievements/users/${id}/courses?status=1`,
    method: 'GET',
    params,
  });
export const getListAchievementCourseCompletedApi = async (params, id) =>
  request({
    url: `admin/achievements/users/${id}/courses-completed`,
    method: 'GET',
    params,
  });
export const getListAchievementCourseNoCompletedApi = async (params, id) =>
  request({
    url: `admin/achievements/users/${id}/courses-uncompleted`,
    method: 'GET',
    params,
  });

export const getListAchievementCourseStudyingApi = async (params, id) =>
  request({
    url: `admin/achievements/users/${id}/courses-studying`,
    method: 'GET',
    params,
  });

export const getChartDataApi = async (params) =>
  request({
    url: 'admin/achievements',
    method: 'GET',
    params,
  });
