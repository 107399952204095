import request from 'services/request';

export const getListExamApi = async (data) =>
  request({
    url: '/admin/exams',
    method: 'POST',
    data,
  });

export const deleteExamApi = async (id) =>
  request({
    url: `/admin/exams/delete/${id}`,
    method: 'DELETE',
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const createExamApi = async (data) =>
  request({
    url: `/admin/exams/create`,
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });

export const updateExamApi = async (id, data) =>
  request({
    url: `/admin/exams/update/${id}`,
    method: 'PUT',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });

export const getDetailsExamApi = async (id) =>
  request({
    url: `/admin/exams/detail/${id}`,
    method: 'GET',
  });

export const getListCreatorApi = async (params) =>
  request({
    url: '/admin/exams/listCreatedBy',
    method: 'GET',
    params,
  });

export const getListSkillsApi = async (params) =>
  request({
    url: '/admin/exams/listSkills',
    method: 'GET',
    params,
  });
