/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { Avatar, Tooltip } from 'antd';
import { ReactSVG as SVG } from 'react-svg';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from 'components/commons/Typo';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import { getListNotification } from 'store/user/overviewSlice';
import { updateLayoutApi } from 'services/apis/other/layout';

import { getMe, logOut } from 'store/other/authSlice';
import { ROUTES_ADMIN, ROUTES_USER } from 'routes/constants';
import { ASSETS_URL } from 'constants/configs';
import { onMessageListener } from 'services/firebaseInit';

import logo from 'assets/icons/logo_black.svg';
import logout from 'assets/icons/exit_to_app.svg';
import avatar from 'assets/icons/avatar.svg';
import bell from 'assets/icons/bell.svg';
import switchIcon from 'assets/image/switch.png';

import * as S from './styled';

import { data } from './mock-data';
import { user_data } from './mock-data-user';

export default function Layout({ children }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [active, set_active] = useState('');
  const [collapsed, set_collapsed] = useState(false);

  // ACTIVE LAYOUT
  // true ADMIN
  // false USER

  const info = useSelector((state) => state.authReducer.currentUser);
  const unread = useSelector((state) => state.userOverviewReducer.unread);

  onMessageListener()
    .then((payload) => {
      dispatch(getListNotification());
    })
    .catch((err) => console.log('ERR', err));

  let userPermissions = {};
  if (info?.roles?.length > 0) {
    info.roles.forEach((role) => {
      userPermissions = {
        ...userPermissions,
        ...role.permissions,
      };
    });
  }

  useEffect(() => {
    dispatch(getListNotification());
  }, [dispatch]);

  useEffect(() => {
    set_active(location?.pathname);
  }, [location?.pathname]);
  const handleLogOutAPI = async () => {
    await dispatch(logOut());
  };

  const handleChangeView = async () => {
    const res = await updateLayoutApi();
    history.push(
      info?.admin_layout === 1 ? ROUTES_USER.OVERVIEW_USER_PAGE : ROUTES_ADMIN.OVERVIEW_PAGE
    );
    if (res.status === 200) {
      dispatch(getMe());
    }
  };

  return (
    <S.Layout>
      <S.Hd className="header">
        <S.DFlex>
          <Link
            to={
              info?.admin_layout === 1 ? ROUTES_ADMIN.OVERVIEW_PAGE : ROUTES_USER.OVERVIEW_USER_PAGE
            }
          >
            <img src={logo} alt="logo" />
          </Link>
        </S.DFlex>
        <div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
          {!info?.is_admin && info?.roles?.length > 1 && (
            <S.SwitchView onClick={() => handleChangeView()}>
              <img src={switchIcon} alt="" />
              {info?.admin_layout === 1 ? 'Chuyển đến trang nhân viên' : 'Chuyển đến trang quản lý'}
            </S.SwitchView>
          )}

          {!info?.is_admin &&
            ((info?.admin_layout === 0 && info?.roles?.length > 1) ||
              info?.roles?.length === 1) && (
              <S.WrapBell onClick={() => history.push(ROUTES_USER.NOTIFICATION_PAGE)}>
                <SVG src={bell} />
                {unread !== null && (
                  <>
                    <div className="noti-qty"></div>
                    <div className="qty">
                      <div
                        style={{
                          width: '10px',
                          height: '10px',
                          textAlign: 'center',
                          paddingLeft: '1px',
                        }}
                      >
                        {unread}
                      </div>
                    </div>
                  </>
                )}
              </S.WrapBell>
            )}

          <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <Avatar
              size={40}
              src={info?.avatar ? `${ASSETS_URL}/${info?.avatar}` : avatar}
            ></Avatar>
            <Text isBold style={{ color: '#303841', textTransform: 'capitalize' }}>
              {info.name}
            </Text>
          </div>
        </div>
      </S.Hd>
      <S.Layout>
        <S.Sd
          width={264}
          className={`site-layout-background ${
            (info?.roles?.length === 1 || info?.admin_layout === 0) && 'menu-user'
          }`}
          collapsible
          collapsed={collapsed}
          onCollapse={() => set_collapsed(!collapsed)}
          trigger={null}
          showTab={collapsed}
          style={{ background: '#171731' }}
        >
          <div
            style={{
              position: 'absolute',
              right: '0px',
              top: '-3px',
              cursor: 'pointer',
            }}
          >
            {collapsed ? (
              <MenuUnfoldOutlined
                style={{ background: '#f8d000', color: 'white' }}
                onClick={() => set_collapsed(!collapsed)}
              />
            ) : (
              <MenuFoldOutlined
                style={{ background: '#f8d000', color: 'white' }}
                onClick={() => set_collapsed(!collapsed)}
              />
            )}
          </div>
          {(info?.roles?.length !== 1 && info?.admin_layout === 1 ? data : user_data)?.map(
            (item) => (
              <>
                <S.WrapParent key={item?.id} showTab={collapsed}>
                  {item?.menus
                    ?.map(
                      (k) =>
                        info?.is_admin ||
                        k?.permission === 'all' ||
                        userPermissions[k?.permission]?.length > 0 ||
                        info?.admin_layout === 0
                    )
                    .includes(true) && <S.Parent showTab={collapsed}>{item?.title}</S.Parent>}

                  {info?.is_admin === 1 && <S.Parent showTab={collapsed}>{item?.title}</S.Parent>}
                  <S.WrapChild>
                    {item?.menus?.map((k) =>
                      collapsed
                        ? (info?.is_admin ||
                            k?.permission === 'all' ||
                            userPermissions[k?.permission]?.length > 0 ||
                            info?.admin_layout === 0) && (
                            <Tooltip placement="right" title={k?.desc}>
                              <S.Child
                                key={k?.id}
                                onClick={() => {
                                  history.push(k?.link);
                                }}
                                active={active.includes(k?.keyword)}
                              >
                                <SVG
                                  style={{
                                    color: active.includes(k?.keyword) ? '#f8d000' : '#FFFFFF',
                                  }}
                                  src={k?.icon}
                                />
                              </S.Child>
                            </Tooltip>
                          )
                        : (info?.is_admin ||
                            k?.permission === 'all' ||
                            userPermissions[k?.permission]?.length > 0 ||
                            info?.admin_layout === 0) && (
                            <S.Child
                              key={k?.id}
                              onClick={() => {
                                history.push(k?.link);
                              }}
                              active={active.includes(k?.keyword)}
                            >
                              <SVG
                                style={{
                                  color: active.includes(k?.keyword) ? '#f8d000' : '#FFFFFF',
                                }}
                                src={k?.icon}
                              />
                              {k?.desc}
                            </S.Child>
                          )
                    )}
                  </S.WrapChild>
                </S.WrapParent>
                {collapsed && <hr style={{ background: '#ffb71c' }} />}
              </>
            )
          )}
          <S.WrapLogOut>
            <S.LogOut onClick={() => handleLogOutAPI()}>
              <img src={logout} alt="" />
              {!collapsed ? 'Đăng xuất' : ''}
            </S.LogOut>
          </S.WrapLogOut>
        </S.Sd>

        <S.Layout>
          <S.Contents>{children}</S.Contents>
        </S.Layout>
      </S.Layout>
    </S.Layout>
  );
}
