import {
  getListAchievementsApi,
  getListAchievementUserApi,
  getListAchievementUserApiDetail,
  getListAchievementPersonApi,
  getListAchievementCourseJoinApi,
  getListAchievementCourseCompletedApi,
  getListAchievementCourseNoCompletedApi,
  getListAchievementCourseStudyingApi,
  getChartDataApi,
} from 'services/apis/admin/achievement';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getListAchievement = createAsyncThunk(
  'achievement/GET_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListAchievementsApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListAchievementUser = createAsyncThunk(
  'achievement/GET_LIST_USER',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListAchievementUserApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListAchievementUserDetail = createAsyncThunk(
  'achievement/GET_LIST_USER_DETAIL',
  async ({ filters, id }, { rejectWithValue }) => {
    try {
      const response = await getListAchievementUserApiDetail(filters, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListAchievementPerson = createAsyncThunk(
  'achievement/GET_LIST_PERSON',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getListAchievementPersonApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListAchievementCourseJoin = createAsyncThunk(
  'achievement/GET_LIST_COURSE_JOIN',
  async ({ filters, id }, { rejectWithValue }) => {
    try {
      const response = await getListAchievementCourseJoinApi(filters, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListAchievementCourseCompleted = createAsyncThunk(
  'achievement/GET_LIST_COURSE_COMPLETED',
  async ({ filters, id }, { rejectWithValue }) => {
    try {
      const response = await getListAchievementCourseCompletedApi(filters, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListAchievementCourseNoCompleted = createAsyncThunk(
  'achievement/GET_LIST_COURSE_NO_COMPLETED',
  async ({ filters, id }, { rejectWithValue }) => {
    try {
      const response = await getListAchievementCourseNoCompletedApi(filters, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListAchievementCourseStudying = createAsyncThunk(
  'achievement/GET_LIST_COURSE_STUDYING',
  async ({ filters, id }, { rejectWithValue }) => {
    try {
      const response = await getListAchievementCourseStudyingApi(filters, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getChartData = createAsyncThunk(
  'achievement/CHART_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getChartDataApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  listAchievement: [],
  listAchievementUser: [],
  listAchievementUserDetail: [],
  listAchievementPerson: [],
  listAchievementCoureJoin: [],
  listAchievementCourseCompleted: [],
  listAchievementCourseNoCompleted: [],
  listAchievementCourseStudying: [],
  chartData: [],
  paginate: [],
  paginateJoin: {},
  paginateCompleted: {},
  paginateNoCompleted: {},
  paginateStudying: {},
  loading: false,
  error: {},
};

const achievementSlice = createSlice({
  name: 'achievement',
  initialState,

  extraReducers: {
    [getListAchievement.pending]: (state) => {
      state.loading = true;
    },
    [getListAchievement.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListAchievement.fulfilled]: (state, action) => {
      state.listAchievement = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },

    [getListAchievementUser.pending]: (state) => {
      state.loading = true;
    },
    [getListAchievementUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListAchievementUser.fulfilled]: (state, action) => {
      state.listAchievementUser = action.payload.data;
      state.loading = false;
      state.error = {};
    },

    [getListAchievementUserDetail.pending]: (state) => {
      state.loading = true;
    },
    [getListAchievementUserDetail.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListAchievementUserDetail.fulfilled]: (state, action) => {
      state.listAchievementUserDetail = action.payload.data;
      state.loading = false;
      state.paginate = action.payload.paginate;
      state.error = {};
    },

    [getListAchievementPerson.pending]: (state) => {
      state.loading = true;
    },
    [getListAchievementPerson.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListAchievementPerson.fulfilled]: (state, action) => {
      state.listAchievementPerson = action.payload.data;
      state.loading = false;
      state.paginate = action.payload.paginate;
      state.error = {};
    },

    [getListAchievementCourseJoin.pending]: (state) => {
      state.loading = true;
    },
    [getListAchievementCourseJoin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListAchievementCourseJoin.fulfilled]: (state, action) => {
      state.listAchievementCoureJoin = action.payload.data;
      state.loading = false;
      state.paginateJoin = action.payload.paginate;
      state.error = {};
    },

    [getListAchievementCourseCompleted.pending]: (state) => {
      state.loading = true;
    },
    [getListAchievementCourseCompleted.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListAchievementCourseCompleted.fulfilled]: (state, action) => {
      state.listAchievementCourseCompleted = action.payload.data;
      state.loading = false;
      state.paginateCompleted = action.payload.paginate;
      state.error = {};
    },

    [getListAchievementCourseNoCompleted.pending]: (state) => {
      state.loading = true;
    },
    [getListAchievementCourseNoCompleted.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListAchievementCourseNoCompleted.fulfilled]: (state, action) => {
      state.listAchievementCourseNoCompleted = action.payload.data;
      state.loading = false;
      state.paginateNoCompleted = action.payload.paginate;
      state.error = {};
    },

    [getListAchievementCourseStudying.pending]: (state) => {
      state.loading = true;
    },
    [getListAchievementCourseStudying.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListAchievementCourseStudying.fulfilled]: (state, action) => {
      state.listAchievementCourseStudying = action.payload.data;
      state.loading = false;
      state.paginateStudying = action.payload.paginate;
      state.error = {};
    },

    [getChartData.pending]: (state) => {
      state.loading = true;
    },
    [getChartData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getChartData.fulfilled]: (state, action) => {
      state.chartData = action.payload.data.courses;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: achievementReducer } = achievementSlice;
export default achievementReducer;
