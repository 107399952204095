const preRoute = '';
const preAdmin = '/admin';
const preUser = '/user';

export const ROUTES = {
  HOME: '/',
  LOGIN: `${preRoute}/login`,
};

export const ROUTES_ADMIN = {
  COURSE_MANAGEMENT: `${preAdmin}/course-management`,
  CREATE_QUESTION: `${preAdmin}/question-management/create-question/:id`,
  EDIT_QUESTION: `${preAdmin}/question-management/edit-question/:id/:params`,
  QUESTION_MANAGEMENT: `${preAdmin}/question-management`,
  CREATE_EXAM: `${preAdmin}/exam-management/create-exam`,
  UPDATE_EXAM: `${preAdmin}/exam-management/update-exam/:id`,
  EXAM_MANAGEMENT: `${preAdmin}/exam-management`,
  SCORING_TEST: `${preAdmin}/scoring-test`,
  EMPLOYEE_PAGE: `${preAdmin}/employee`,
  CREATE_EMPLOYEE: `${preAdmin}/employee/create-employee`,
  SKILL_MANAGEMENT: `${preAdmin}/skill-management`,
  RANKING_SCORE: `${preAdmin}/scoring-test/detail/ranking-score/:cid/:eid/:rid/:lid`,
  LECTURER_PAGE: `${preAdmin}/lecturer`,
  CREATE_LECTURER: `${preAdmin}/create-lecturer`,
  OVERVIEW_PAGE: `${preAdmin}/overview`,
  LEVEL_PAGE: `${preAdmin}/level-page`,
  PARTS_MANAGEMENT: `${preAdmin}/parts-management`,
  SCORING_TEST_DETAIL: `${preAdmin}/scoring-test/detail/:id`,
  LESSON_MANAGEMENT: `${preAdmin}/lesson-management`,
  LESSON_DETAIL: `${preAdmin}/lesson-management/detail/:id`,
  COURSE_MANAGEMENT_DETAIL: `${preAdmin}/course-management/detail/:id/`,
  CALENDAR_PAGE: `${preAdmin}/calendar`,
  PROFILE_MANAGEMENT: `${preAdmin}/profile-management`,
  CREATE_COURSE: `${preAdmin}/course-management/create-course`,
  EDIT_COURSE: `${preAdmin}/course-management/edit-course/:id`,
  CREATE_LESSON: `${preAdmin}/lesson-management/create-lesson`,
  UPDATE_LESSON: `${preAdmin}/lesson-management/update-lesson/:id`,
  ACHIEVEMENT_MANAGEMENT: `${preAdmin}/achievement-management`,
  ACHIEVEMENT_MANAGEMENT_DETAIL: `${preAdmin}/achievement-management/detail/:id`,
  TOP_STAFF: `${preAdmin}/overview/top-staff`,
  IMPORT_QUESTION: `${preAdmin}/question-management/import-question`,
  IMPORT_EMPLOYEE: `${preAdmin}/employee/import-employee`,
  COURSE_CATALOG: `${preAdmin}/course-catalog`,
  COURSE_CATALOG_DETAIL: `${preAdmin}/course-catalog/detail/:id/:name`,
};

export const ROUTES_USER = {
  COURSE_MANAGEMENT: `${preUser}/course-management`,
  COURSE_MANAGEMENT_DETAIL: `${preUser}/course-management/detail/:id/`,
  PROFILE_MANAGEMENT: `${preUser}/profile-management`,
  OVERVIEW_USER_PAGE: `${preUser}/overview-user-page`,
  UPCOMING_CLASS: `${preUser}/upcoming-class`,
  NOTIFICATION_PAGE: `${preUser}/notification`,
  POLICY_PAGE: `${preUser}/policy`,
};
