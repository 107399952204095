import { configureStore } from '@reduxjs/toolkit';
import authReducer from './other/authSlice';
import courseReducer from './admin/courseSlice';
import examReducer from './admin/examSlice';
import questionReducer from './admin/questionSlice';
import skillReducer from './admin/skillSlice';
import levelReducer from './admin/levelSlice';
import adminReducer from './admin/adminSlice';
import partsReducer from './admin/partsSlice';
import userReducer from './admin/userSlice';
import markReducer from './admin/markSlice';
import lessonReducer from './admin/lessonSlice';
import catalogReducer from './admin/catalog';
import achievementReducer from './admin/achievementSlice';
import userCourseReducer from './user/courseSlice';
import overviewReducer from './admin/overviewSlice';
import userOverviewReducer from './user/overviewSlice';
import schedulesReducer from './user/upcomingSlice';
import roleReducer from './admin/roleSlice';
import notificationReducer from './admin/notificationSlice';
import layoutReducer from './other/layoutSlice';
import noPermissionReducer from './other/noPermissionSlice';
import policyReducer from './other/policySlice';
import calendarReducer from './admin/calendarSlice';

export const store = configureStore({
  reducer: {
    layoutReducer,
    authReducer,
    courseReducer,
    questionReducer,
    examReducer,
    skillReducer,
    levelReducer,
    adminReducer,
    partsReducer,
    userReducer,
    markReducer,
    lessonReducer,
    userCourseReducer,
    catalogReducer,
    achievementReducer,
    overviewReducer,
    userOverviewReducer,
    schedulesReducer,
    roleReducer,
    notificationReducer,
    noPermissionReducer,
    policyReducer,
    calendarReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
