const colors = {
  blueOxford: '#373B53',
  gray: '#C1C1C1',
  gray1: '#333333',
  bgGray: '#f8f8fb',
  bgDrakGray: '#E5E5E5',
  bgGray1: '#f8f8fb',
  bgGray2: '#FAFAFA',
  gray3: '#999999',
  blue: '#00B4D8',
  red: '#F44336',
  redError: '#BC0000',
  green: '#00AB5E',
  white: '#fff',
  primaryButtonColor: '#f8d000',
  grayButtonColor: '#868A96',
  activeColor: '#f8d000',
  borderColor: '#dce0ea',
  dark: '#303841',
  linkColor: '#868a96',
  disableText: '#868A96',
};

const fontSize = {
  xs: '12px',
  sm: '14px',
  base: '16px',
  md: '18px',
  lg: '20px',
  xl: '24px',
  xxl: '32px',
  xxxl: '40px',
};

const fontWeight = {
  regular: 400,
  medium: 500,
  bold: 600,
  extraBold: 700,
};

const zIndex = {
  base: 1,
  dropdown: 10,
  backdrop: 50,
  modal: 100,
};

const boxShadow = {
  box: '0px 4px 4px rgba(48, 48, 48, 0.25)',
};

const borderRadius = {
  base: '4px',
  md: '8px',
  lg: '25.5px',
  xl: '28px',
};

const padding = {
  pdHeader: '8px 16px',
  pdSideBar: '20px',
};

export default {
  colors,
  fontSize,
  fontWeight,
  zIndex,
  boxShadow,
  borderRadius,
  padding,
};
