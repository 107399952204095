import request from 'services/request';

export const getListCourseApi = async (params) =>
  request({
    url: '/course/list',
    method: 'GET',
    params,
  });

export const getCourseDetailApi = async (id) =>
  request({
    url: `/course/detail/${id}`,
    method: 'GET',
  });

export const getListLessonByRouteApi = async (id) =>
  request({
    url: `/course/list-lesson/${id}`,
    method: 'GET',
  });

export const getDetailExamApi = async (id) =>
  request({
    url: `/course/detail-exam/${id}`,
    method: 'GET',
  });

export const getDetailLessonApi = async (params) =>
  request({
    url: '/course/detail-lesson',
    method: 'GET',
    params,
  });

export const getSubmittedTestApi = async (id) =>
  request({
    url: `course/detail-result-test/${id}`,
    method: 'GET',
  });

export const getListRatingApi = async (id) =>
  request({
    url: `course/ratings/${id}`,
    method: 'GET',
  });

export const joinCourseApi = async (data) =>
  request({
    url: '/course/join',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });

export const updateStatusLessonApi = async (data) =>
  request({
    url: '/course/update-status-lesson',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });

export const updateViewDurationApi = async (data) =>
  request({
    url: '/course/update-view-duration',
    method: 'POST',
    data,
  });

export const submitTestApi = async (data) =>
  request({
    url: 'course/send-test',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });

export const submitRatingApi = async (id, data) =>
  request({
    url: `course/ratings/${id}/create`,
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });
