import {
  getListCourseActiveApi,
  getListExamInCourseApi,
  getListTestInExamApi,
  getInfoExamApi,
  getDetailTestApi,
  updateMarkTestPointApi,
  getListCourseCompleteApi,
} from 'services/apis/admin/mark';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getListCourseActive = createAsyncThunk(
  'mark/GET_LIST_COURSE_ACTIVE',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListCourseActiveApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListCourseComplete = createAsyncThunk(
  'mark/GET_LIST_COURSE_COMPLETE',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListCourseCompleteApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListExamInCourse = createAsyncThunk(
  'mark/GET_LIST_EXAM',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getListExamInCourseApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListTestInExam = createAsyncThunk(
  'mark/GET_LIST_TEST',
  async (data, { rejectWithValue }) => {
    try {
      const response = await getListTestInExamApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getInfoExam = createAsyncThunk(
  'mark/GET_INFO_EXAM',
  async (data, { rejectWithValue }) => {
    try {
      const response = await getInfoExamApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getDetailTest = createAsyncThunk(
  'mark/GET_DETAIL_TEST',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getDetailTestApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const updateMarkTestPoint = createAsyncThunk(
  'mark/UPDATE_MARK',
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateMarkTestPointApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  listCourseActive: [],
  listCourseComplete: [],
  listExam: [],
  listPerson: [],
  infoExam: {},
  testDetail: {},
  paginate: {},
  loading: false,
  error: {},
};

const markSlice = createSlice({
  name: 'mark',
  initialState,

  extraReducers: {
    [getListCourseActive.pending]: (state) => {
      state.loading = true;
    },
    [getListCourseActive.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListCourseActive.fulfilled]: (state, action) => {
      state.listCourseActive = action.payload.data;
      state.loading = false;
      state.error = {};
    },

    [getListCourseComplete.pending]: (state) => {
      state.loading = true;
    },
    [getListCourseComplete.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListCourseComplete.fulfilled]: (state, action) => {
      state.listCourseComplete = action.payload.data;
      state.loading = false;
      state.error = {};
    },

    [getListExamInCourse.pending]: (state) => {
      state.loading = true;
    },
    [getListExamInCourse.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListExamInCourse.fulfilled]: (state, action) => {
      state.listExam = action.payload;
      state.loading = false;
      state.error = {};
    },

    [getListTestInExam.pending]: (state) => {
      state.loading = true;
    },
    [getListTestInExam.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListTestInExam.fulfilled]: (state, action) => {
      state.listPerson = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },

    [getInfoExam.pending]: (state) => {
      state.loading = true;
    },
    [getInfoExam.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getInfoExam.fulfilled]: (state, action) => {
      state.infoExam = action.payload;
      state.loading = false;
      state.error = {};
    },

    [getDetailTest.pending]: (state) => {
      state.loading = true;
    },
    [getDetailTest.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getDetailTest.fulfilled]: (state, action) => {
      state.testDetail = action.payload;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: markReducer } = markSlice;
export default markReducer;
