import {
  getListCourseApi,
  getListCourseDetailApi,
  getListCategoriesApi,
  getListEmployeeJoinApi,
  getListEmployeeCompleteApi,
  getListUserApi,
  getListCreatorApi,
  getListRatingApi,
} from 'services/apis/admin/course';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getListCourse = createAsyncThunk(
  'course/GET_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListCourseApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListCategories = createAsyncThunk(
  'course/GET_LIST_CATEGORIES',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListCategoriesApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListCourseDetail = createAsyncThunk(
  'course/GET_LIST_DETAIL',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getListCourseDetailApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListUser = createAsyncThunk(
  'course/GET_LIST_USER',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListUserApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListEmployeeJoin = createAsyncThunk(
  'course/GET_LIST_EMPLOYEE_JOIN',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListEmployeeJoinApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListEmployeeComplete = createAsyncThunk(
  'course/GET_LIST_EMPLOYEE_COMPLETE',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListEmployeeCompleteApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListCreator = createAsyncThunk(
  'course/GET_LIST_CREATOR',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListCreatorApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListRating = createAsyncThunk(
  'course/GET_LIST_RATING',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getListRatingApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  listCourse: [],
  listCategories: [],
  listUser: [],
  listCreator: [],
  listEmployeeJoin: [],
  listEmployeeComplete: [],
  listRating: [],
  detail: [],
  paginate: {},
  loading: false,
  error: {},
};

const courseSlice = createSlice({
  name: 'course',
  initialState,

  extraReducers: {
    [getListCourse.pending]: (state) => {
      state.loading = true;
    },
    [getListCourse.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListCourse.fulfilled]: (state, action) => {
      state.listCourse = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },

    [getListCategories.pending]: (state) => {
      state.loading = true;
    },
    [getListCategories.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListCategories.fulfilled]: (state, action) => {
      state.listCategories = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },

    [getListCourseDetail.pending]: (state) => {
      state.loading = true;
    },
    [getListCourseDetail.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListCourseDetail.fulfilled]: (state, action) => {
      state.detail = action.payload.data;
      state.loading = false;
      state.error = {};
    },

    [getListUser.pending]: (state) => {
      state.loading = true;
    },
    [getListUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListUser.fulfilled]: (state, action) => {
      state.listUser = action.payload.data;
      state.loading = false;
      state.error = {};
    },

    [getListEmployeeJoin.pending]: (state) => {
      state.loading = true;
    },
    [getListEmployeeJoin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListEmployeeJoin.fulfilled]: (state, action) => {
      state.listEmployeeJoin = action.payload.data;
      state.loading = false;
      state.error = {};
    },

    [getListEmployeeComplete.pending]: (state) => {
      state.loading = true;
    },
    [getListEmployeeComplete.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListEmployeeComplete.fulfilled]: (state, action) => {
      state.listEmployeeComplete = action.payload.data;
      state.loading = false;
      state.error = {};
    },

    [getListCreator.pending]: (state) => {
      state.loading = true;
    },
    [getListCreator.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListCreator.fulfilled]: (state, action) => {
      state.listCreator = action.payload.data;
      state.loading = false;
      state.error = {};
    },

    [getListRating.pending]: (state) => {
      state.loading = true;
    },
    [getListRating.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListRating.fulfilled]: (state, action) => {
      state.listRating = action.payload.data;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: courseReducer } = courseSlice;
export default courseReducer;
